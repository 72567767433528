<template>
  <div class="wrap">
    <el-row type="flex" justify="center" align="center" class="home">
      <!-- <h1>Welcome To Sports Data Center</h1>
      <i class="iconfont icon-shujutansuoshujuzhongxinmoxingguanli"></i> -->
      <div class="to-list">
        <h3>To Do List</h3>
        <div class="tr" v-for="(item, index) in list" :key="item.key">
          <div class="td">{{item.name}}</div>
          <div class="td">{{info[item.key]}}</div>
          <div class="td"> <el-link type="primary" @click="goTo(index+1)">Go To</el-link> </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import { toDoList } from '@/service'
let timer
export default {
  data () {
    return {
      info: {},
      list: [
        {
          name: 'Third match to push',
          key:'thirdMatchToPushCount'
        },
        {
          name: 'Standard match to push (today)',
          key:'standardMatchCount'
        },
        {
          name: 'Third match to map (today)',
          key:'thirdMatchToMapCount'
        },
      ]
    }
  },
  mounted() {
    this.getList()
    timer = setInterval(() => {
      this.getList()
    }, 30000);
  },
  methods: {
    getList () {
      toDoList().then(res => {
        if(res.code === 0) {
          this.info = res.data
        }
      })
    },
    goTo(type) {
      switch (type) {
        case 1:
          this.$router.push('/match?formatPushTime=1')
          break;
        case 2:
          this.$router.push('/match?status=0')
          break;
        case 3:
          this.$router.push('/match-mapping?status=0')
          break;
      
        default:
          break;
      }

    }
  },
  beforeDestroy() {
    clearInterval(timer)
  }
}
</script>

<style lang="scss" scoped>
.home {
  margin-top: 230px;
  // font-size: 46px;
  flex-direction: column;
  text-align: center;
  i{
	  font-size: 260px;
	  display: inline-block;
	  margin-top: 50px;
	  color: #333;

  }
  .to-list{
    margin: auto;
    h3{
      text-align: left;
      padding: 10px;
      background: #ccc;
      color: #333;
    }
    .tr{
      display: flex;
      &:last-child .td{
        border-bottom: 1px solid #dedede;
      }
      .td{
        padding: 6px 8px;
        border-top: 1px solid #dedede;
        border-right: 1px solid #dedede;
        min-width: 110px;
        &:first-child{
          border-left: 1px solid #dedede;
          width: 300px;
          text-align: left;
        }
      }
    }
  }
}
</style>